modifyMethod('frappe.ui.form.Toolbar', 'set_page_actions', function () {
	const frm = this.frm;

	if (!frm.meta.is_submittable || frm.is_dirty()) return;

	const allow_edit = frm.perm[0].amend && frm.perm[0].cancel;
	if (!allow_edit || frm.page.current_view_name == 'print') return;

	if (frm.doc.docstatus === 1) {
		frm.page.set_primary_action('Edit', function () {
			const d = new frappe.ui.Dialog({
				title: 'Reason',
				fields: [
					{
						label: 'Reason',
						fieldname: 'reason',
						fieldtype: 'Small Text',
						reqd: 1,
					}
				],
				primary_action_label: 'Edit',
				primary_action(values) {
					d.hide();
					frm.validate_form_action('Cancel');
					frappe.validated = true;
					frm.script_manager.trigger("before_cancel").then(function () {
						async function after_cancel(r) {
							if (r.exc) {
								frm.handle_save_fail(btn, on_error);
								return;
							}

							frm.refresh();
							await frm.script_manager.trigger("after_cancel");

							frappe.call({
								method: 'edit_after_submit.api.make_document_draft',
								args: {
									doctype: frm.doctype,
									docname: frm.docname,
									user: frappe.user.name,
									reason: values.reason,
									action: "Edit",
								},
								callback() {
									frm.reload_doc();
								}
							});
						};
						frappe.ui.form.save(frm, "cancel", after_cancel);
					});
				}
			});

			d.show();

		})
	}

	if (frm.doc.docstatus === 2) {
		frm.page.add_menu_item('Convert to Draft', function () {
			const d = new frappe.ui.Dialog({
				title: 'Reason',
				fields: [
					{
						label: 'Reason',
						fieldname: 'reason',
						fieldtype: 'Small Text',
						reqd: 1,
					}
				],
				primary_action_label: 'Convert',
				primary_action(values) {
					d.hide();
					frappe.call({
						method: 'edit_after_submit.api.make_document_draft',
						args: {
							doctype: frm.doctype,
							docname: frm.docname,
							user: frappe.user.name,
							reason: values.reason,
							action: "Convert",
						},
						callback() {
							frm.reload_doc();
						}
					});
				}
			});

			d.show();

		})
	}
});